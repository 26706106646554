import { getRoot, getUri } from 'core/router'

import store from 'store/store'

const regexp = /#(.*?)=(.*)/
let ready = false

const onStoreUpdate = key => (value, previous) => {
  if (value === null) {
    if (~window.location.hash.indexOf(key + '='))
      history.replaceState(null, null, getUri().split('#')[0])

    return
  }

  if (key !== 'popin') store.popin.set(null)
  if (key !== 'menu') store.menu.set(null)
  if (key !== 'panel') store.panel.set(null)
  if (key !== 'search') store.search.set(null)

  const hash = '#' + key + '=' + value.replace(getRoot(), '')

  if (window.location.hash !== hash) {
    // router.navigate(pathName, { callHandler: false, callHooks: false })
    // router.pause()
    window.location.replace(window.location.origin + window.location.pathname + hash)
    // router.resume()
  }
}

const updateStoreWithHash = (match, forcedValue = undefined) => {
  if (!match) return
  const key = match[1]
  const value = forcedValue === undefined ? match[2] : forcedValue
  if (!key.match(/^menu|panel|search|popin$/)) return
  const storeValue = store[key]?.value && store[key]?.value.replace(getRoot(), '')
  if (storeValue !== value) store[key]?.set(value)
}

const onHashChange = (event) => {
  if (!ready) return
  const previousMatch = event && event.oldURL && event.oldURL.match(regexp)

  if (!window.location.hash) {
    if (previousMatch) updateStoreWithHash(previousMatch, null)
    return
  }
  const match = window.location.hash.match(regexp)
  updateStoreWithHash(match)
}

store.path.listen((path, previousPath) => {
  if (path.match(regexp)) onHashChange()
})

window.addEventListener('hashchange', onHashChange)
// store.menu.listen(onStoreUpdate('menu'))
store.popin.listen(onStoreUpdate('popin'))
store.panel.listen(onStoreUpdate('panel'))
store.search.listen(onStoreUpdate('search'))

window.addEventListener('keydown', ({ key }) => {
  if (key === 'Escape') {
    store.popin.set(null)
    // store.menu.set(null)
    store.panel.set(null)
    store.search.set(null)
  }
})

const start = () => {
  ready = true
  onHashChange()
}

export { start as startPanels }

export default {}
