/* global FB, google */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { each } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import promise from 'helpers/promise';
import Component from 'navigation/component/Component';
import config from 'core/config';
import { bindMethod } from 'helpers/bind';
var facebookInit = false;
var facebookPromise = promise.defer();
var SocialLogin = /** @class */ (function (_super) {
    __extends(SocialLogin, _super);
    function SocialLogin(el, _a) {
        var parent = _a.parent;
        var _this = _super.call(this, el, { parent: parent }) || this;
        Object.defineProperty(_this, "pageManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "googleButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onGoogleClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                var g;
                return __generator(this, function (_a) {
                    g = window.google;
                    if (!g)
                        return [2 /*return*/];
                    document.cookie = 'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    return [2 /*return*/];
                });
            }); }
        });
        Object.defineProperty(_this, "onFacebookClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var w = window;
                facebookPromise.promise.then(function () {
                    w.FB.getLoginStatus(function (response) {
                        // if (response.status === 'connected') return this.onFBLogin(response)
                        w.FB.login(function (response) {
                            if (response.status === 'connected')
                                return _this.onFBLogin(response);
                        }, { scope: 'public_profile, email' });
                    });
                });
            }
        });
        Object.defineProperty(_this, "onGoogleLogin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (response) {
                var profile = jwtDecode(response.credential);
                if (!profile)
                    return;
                _this.sendData({
                    google: true,
                    token: response.credential,
                    email: profile.email,
                    lastname: profile.family_name,
                    firstname: profile.given_name
                });
            }
        });
        _this.pageManager = parent.pageManager;
        _this.bindRefs();
        if (!facebookInit)
            _this.initFacebook();
        _this.initGoogle();
        return _this;
    }
    Object.defineProperty(SocialLogin.prototype, "initGoogle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!window.google) {
                setTimeout(this.initGoogle, 300);
                return;
            }
            google.accounts.id.initialize({
                client_id: config.googleId,
                callback: this.onGoogleLogin,
                use_fedcm_for_prompt: true,
                itp_support: true,
                ux_mode: 'popup'
            });
            var el = this.refs.googleBigButton || this.refs.googleLoginInner;
            google.accounts.id.renderButton(el, { theme: 'outline', size: 'large', width: '100%' } // customization attributes
            );
            // (google as any).accounts.id.renderButton(this.refs.googleLogin, {
            //   theme: 'outline',
            //   size: 'large'
            // })
            // gapi.load('auth2', () => {
            //   const auth2 = gapi.auth2.init({
            //     client_id: config.googleId,
            //     cookiepolicy: 'single_host_origin'
            //   })
            //   auth2.attachClickHandler(this.refs.googleLogin, {},
            //     this.onGoogleLogin,
            //     (error) => {
            //       console.error(JSON.stringify(error, undefined, 2)) //eslint-disable-line
            //     })
            // })
        }
    });
    Object.defineProperty(SocialLogin.prototype, "initFacebook", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var fjs = document.getElementsByTagName('script')[0];
            if (document.getElementById('facebook-jssdk'))
                return;
            var js = document.createElement('script');
            js.id = 'facebook-jssdk';
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
            facebookInit = true;
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: config.facebookId,
                    cookie: true,
                    xfbml: true,
                    version: 'v18.0'
                });
                facebookPromise.resolve();
            };
        }
    });
    Object.defineProperty(SocialLogin.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            this.refs.facebookLogin[method]('click', this.onFacebookClicked);
            this.refs.googleLogin[method]('click', this.onGoogleClicked);
        }
    });
    Object.defineProperty(SocialLogin.prototype, "onFBLogin", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (response) {
            var _this = this;
            var w = window;
            var accessToken = response.authResponse.accessToken;
            w.FB.api('/me', { fields: 'last_name, first_name, email' }, function (response) {
                _this.sendData({
                    facebook: true,
                    token: accessToken,
                    email: response.email,
                    lastname: response.last_name,
                    firstname: response.first_name
                });
            });
        }
    });
    Object.defineProperty(SocialLogin.prototype, "sendData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            var formData = new FormData();
            each(params, function (value, key) { return formData.append(key, value); });
            var form = this.el.parentNode.querySelector('form') || this.el.parentNode;
            if (!form)
                return;
            var action = form.getAttribute('action') || window.location;
            var csrf = form.querySelector('[name="csrf_token"]');
            if (!csrf)
                return;
            formData.append('csrf_token', csrf.value);
            var p = this.parent;
            if (p.pageManager) {
                p.pageManager.virtual(action, {
                    body: formData, method: 'POST'
                });
            }
            else {
                p.parent.modules.content.virtual(action, {
                    body: formData, method: 'POST'
                });
            }
        }
    });
    return SocialLogin;
}(Component));
export default SocialLogin;
